import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Layout, SEO } from '@src/components';
import { useSelector, useDispatch } from 'react-redux';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import DatePicker from 'react-multi-date-picker';
import debounce from 'debounce';

import { Table } from '../../components/Table';
import LoaderOverlay from '../../components/UI/LoaderOverlay';
import {
  getAllPurchases,
  generateDatoDocumentsData,
  getAllSubscription,
} from '../../utils/mmc-api/api';
import { fetchUser } from '../../store/user';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import './styles.css';
import { TabContent, TabNavItem } from '../../components/UI/Tabs';
import NewsletterView from 'components/Admin/NewsletterView';
import ChallengeQuestionsResponsesView from 'components/Admin/ChallengeQuestionsResponses';
import ChallengeQuestionsView from 'components/Admin/ChallengeQuestions';

const pageSizes = [10, 20, 50];

const AdminPage = () => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('tab1');

  useEffect(() => {
    (async function () {
      if (!user) await dispatch(fetchUser());
      if (user.role !== 'Admin') return (window.location = '/');
      // if (!['admin', 'viewer', ].includes(user?.role.toLowerCase())) return (window.location = '/');
    })();
  }, []);

  return (
    <Layout>
      <SEO title="Admin!" />
      {user?.role == 'Admin' && (
        <div className="Tabs">
          <div className="nav">
            <TabNavItem
              title="Purchased Documents"
              id="tab1"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Subscriptions"
              id="tab2"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Newsletter"
              id="tab3"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Challenge Questions"
              id="tab4"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabNavItem
              title="Challenge Questions Responses"
              id="tab5"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div className="outlet">
            <TabContent id="tab1" activeTab={activeTab}>
              <PurchasedDocsTable />
            </TabContent>
            <TabContent id="tab2" activeTab={activeTab}>
              <SubscriptionTable />
            </TabContent>
            <TabContent id="tab3" activeTab={activeTab}>
              <NewsletterView />
            </TabContent>
            <TabContent id="tab4" activeTab={activeTab}>
              <ChallengeQuestionsView />
            </TabContent>
            <TabContent id="tab5" activeTab={activeTab}>
              <ChallengeQuestionsResponsesView />
            </TabContent>
          </div>
        </div>
      )}
    </Layout>
  );
};

const PurchasedDocsTable = () => {
  const queryClient = useQueryClient();
  const inputRef = useRef(null);
  const user = useSelector((state) => state.user.data);
  const [filter, setFilter] = useState({ type: '', value: '' });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isGeneratingDatoDocs, setIsGeneratingDatoDocs] = useState(false);

  const headers = [
    {
      label: 'Date',
      value: 'created_at',
    },
    {
      label: 'Name',
      value: ['name', 'username'],
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Product name',
      value: 'doc_name',
    },
    {
      label: 'Module',
      value: 'module',
    },
    {
      label: 'Category',
      value: 'category',
    },
    {
      label: 'Price',
      value: 'doc_price',
    },
  ];

  const { data, isLoading, isFetching } = useQuery(
    ['purchased-documents', pageNum, pageSize, filter.value],
    () => getAllPurchases(pageSize, pageNum, filter),
    { staleTime: 30000 },
  );

  const { mutateAsync } = useMutation(() => generateDatoDocumentsData());

  const handlePageNumChange = (value) => {
    setPageNum(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPageNum(1);
  };

  const handleChangeFilterType = (value) => {
    if (filter.type && inputRef?.current) {
      inputRef.current.value = '';
    }

    setFilter({ type: value, value: '' });
    setPageNum(1);
  };

  const debouncedCb = useCallback(
    debounce((fn) => fn(), 750),
    [],
  );

  const onFilterChange = (e) => {
    let value = '';

    if (filter.type == 'date') {
      if (!e?.[0] || !e?.[1]) {
        return;
      }
      value = `${e?.[0]?.format('YYYY-MM-DD').toString()} 00:00:00|${e?.[1]
        ?.format('YYYY-MM-DD')
        .toString()} 23:59:59`;
    } else {
      value = e.target.value;
    }

    debouncedCb(() => {
      setFilter((state) => ({ ...state, value }));
      setPageNum(1);
    });
  };

  const handleGenerateDatoData = async () => {
    setIsGeneratingDatoDocs(true);

    const res = await mutateAsync();

    if (res?.error) {
      alert(res.error.message);
      setIsGeneratingDatoDocs(false);
      return;
    }

    setTimeout(() => {
      queryClient.invalidateQueries(['purchased-documents']);
      setIsGeneratingDatoDocs(false);
    }, 3000);
  };

  return (
    <div className="container ">
      {(isLoading || isFetching || isGeneratingDatoDocs) && <LoaderOverlay />}
      <div style={{ display: 'flex', alignItems: 'center' }} className="pr-1 pl-1">
        <h2>Purchased Documents</h2>
        {user?.role == 'Admin' && (
          <button
            disabled={isGeneratingDatoDocs}
            onClick={() => handleGenerateDatoData()}
            className="w-nav-brand sign-in-badge"
          >
            <span>{isGeneratingDatoDocs ? 'Loading...' : 'Generate Dato Docs'}</span>
          </button>
        )}
      </div>
      <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <SummaryCard
          title="Total Earned"
          value={data?.totalAmount ? `£${data.totalAmount.toFixed(2)}` : null}
        />
      </div>
      <div className="srow x-space-between mt-2 pr-1 pl-1">
        <div className="scolumn 3">
          <label>Filter by</label>
          <select
            className="dropdown"
            value={filter.type}
            onChange={(e) => handleChangeFilterType(e.target.value)}
          >
            <option value=""></option>
            <option value="email">Email</option>
            <option value="product">Product</option>
            <option value="module">Module</option>
            <option value="category">Category</option>
            <option value="date">Date</option>
          </select>
        </div>
        {filter?.type && filter.type != 'date' && (
          <div className="scolumn 6">
            <label>{`Filter ${
              filter?.type ? filter.type.charAt(0).toUpperCase() + filter.type.slice(1) : 'Keyword'
            } `}</label>
            <input
              ref={inputRef}
              className="input"
              id="product"
              type="text"
              onChange={onFilterChange}
            />
          </div>
        )}
        {filter?.type && filter.type == 'date' && (
          <div className="scolumn 6">
            <label>Filter Date</label>
            <DatePicker
              className="rmdp-mobile"
              mobileButtons={[
                {
                  label: 'RESET',
                  type: 'button',
                  className: 'rmdp-button rmdp-action-button',
                },
              ]}
              inputClass="input"
              onChange={onFilterChange}
              range
              rangeHover
              dateSeparator=" to "
              autoFocus
            />
          </div>
        )}
        <div className="scolumn 1">
          <label>Page size</label>
          <select
            className="dropdown"
            value={pageSize}
            onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
          >
            {pageSizes.map((size, i) => (
              <option key={i} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Table
        data={data?.data || []}
        headers={headers}
        totalResults={data?.totalResults || 0}
        pageSize={pageSize}
        pageNum={pageNum}
        changePageNum={handlePageNumChange}
      />
    </div>
  );
};

const SubscriptionTable = () => {
  const inputRef = useRef(null);
  const [filter, setFilter] = useState({ type: '', value: '' });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const headers = [
    {
      label: 'Date',
      value: 'created_at',
    },
    {
      label: 'Name',
      value: ['name', 'username'],
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Start Date',
      value: 'start_date',
    },
    {
      label: 'End Date',
      value: 'end_date',
    },
    {
      label: 'Price',
      value: 'price',
    },
    {
      label: 'Subscription',
      value: 'sub_plan',
    },
  ];

  const { data, isLoading, isFetching } = useQuery(
    ['subscriptions', pageSize, pageNum, filter.value],
    () => getAllSubscription(pageSize, pageNum, filter),
    { staleTime: 60000 },
  );

  const handlePageNumChange = (value) => {
    setPageNum(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPageNum(1);
  };

  const handleChangeFilterType = (value) => {
    if (filter.type && inputRef?.current) {
      inputRef.current.value = '';
    }

    setFilter({ type: value, value: '' });
    setPageNum(1);
  };

  const debouncedCb = useCallback(
    debounce((fn) => fn(), 750),
    [],
  );

  const onFilterChange = (e) => {
    let value = '';

    if (filter.type == 'date') {
      if (!e?.[0] || !e?.[1]) {
        return;
      }
      value = `${e?.[0]?.format('YYYY-MM-DD').toString()} 00:00:00|${e?.[1]
        ?.format('YYYY-MM-DD')
        .toString()} 23:59:59`;
    } else {
      value = e.target.value;
    }

    debouncedCb(() => {
      setFilter((state) => ({ ...state, value }));
      setPageNum(1);
    });
  };

  return (
    <div className="container">
      {(isLoading || isFetching) && <LoaderOverlay />}
      <h2 className="pr-1 pl-1">Subscriptions</h2>
      <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <SummaryCard
          title="Total Earned"
          value={data?.totalAmount ? `£${data.totalAmount.toFixed(2)}` : null}
        />
      </div>
      <div className="srow x-space-between mt-2 pr-1 pl-1">
        <div className="scolumn 3">
          <label>Filter by</label>
          <select
            className="dropdown"
            value={filter.type}
            onChange={(e) => handleChangeFilterType(e.target.value)}
          >
            <option value=""></option>
            <option value="email">Email</option>
            <option value="subscription">Subscription</option>
            <option value="date">Date</option>
          </select>
        </div>
        {filter.type == 'email' && (
          <div className="scolumn 6">
            <label>Filter Email</label>
            <input className="input" type="text" onChange={onFilterChange} />
          </div>
        )}
        {filter.type == 'subscription' && (
          <div className="scolumn 6">
            <label>Filter Subscription</label>
            <select className="dropdown" onChange={onFilterChange}>
              <option value=""></option>
              <option value="1-month">1-Month</option>
              <option value="3-month">3-Month</option>
              <option value="1-year">1-Year</option>
            </select>
          </div>
        )}
        {filter.type == 'date' && (
          <div className="scolumn 6">
            <label>Filter Date</label>
            <DatePicker
              className="rmdp-mobile"
              mobileButtons={[
                {
                  label: 'RESET',
                  type: 'button',
                  className: 'rmdp-button rmdp-action-button',
                  // onClick: (e) => onFilterChange(e),
                },
              ]}
              inputClass="input"
              onChange={onFilterChange}
              range
              rangeHover
              dateSeparator=" to "
              autoFocus
            />
          </div>
        )}
        <div className="scolumn 1">
          <label>Page size</label>
          <select
            className="dropdown"
            value={pageSize}
            onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
          >
            {pageSizes.map((size, i) => (
              <option key={i} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Table
        data={data?.data || []}
        headers={headers}
        totalResults={data?.totalResults || 0}
        pageSize={pageSize}
        pageNum={pageNum}
        changePageNum={handlePageNumChange}
      />
    </div>
  );
};

function SummaryCard({ title, value }) {
  return (
    <div>
      {value && (
        <div
          style={{
            padding: '15px 30px 10px',
            border: 'solid 1px #23d4ff',
            borderRadius: '40px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {title}
          </div>
          <h2
            style={{
              marginTop: '10px',
              textAlign: 'center',
            }}
          >
            {value}
          </h2>
        </div>
      )}
    </div>
  );
}

export default AdminPage;
